import { Button, Dropdown, Menu } from 'antd';
import CcxContextualMenuIcon from '../ccx/icons/CcxContextualMenuIcon';
import IncrementalCreateDatastoreButton from './IncrementalCreateDatastoreButton';
import IncrementalRestoreButton from './IncrementalRestoreButton';
import DeploymentsItem from '../../types/DeploymentsItem';

interface IncrementalListActionsProps {
    readonly record: any;
    readonly restoring?: boolean;
    readonly onConfirmRestore: Function;
    readonly deployment: DeploymentsItem;
}

const IncrementalListActions = ({
    record,
    restoring,
    deployment,
    onConfirmRestore,
}: IncrementalListActionsProps) => {
    const menu = (
        <Menu>
            {(deployment.isMariaDb() || deployment.isPercona()) && (
                <IncrementalCreateDatastoreButton
                    deployment={deployment}
                    record={record}
                />
            )}
            <Menu.Item key={'incremental-restore-button'}>
                <IncrementalRestoreButton
                    restoring={restoring}
                    onConfirmRestore={onConfirmRestore}
                />
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            overlay={menu}
            placement="bottomRight"
            trigger={['click']}
            className={'incremental-menu-dropdown'}
        >
            <Button
                data-testid={`MenuButton`}
                icon={<CcxContextualMenuIcon testId={`MenuButtonIcon`} />}
            />
        </Dropdown>
    );
};

export default IncrementalListActions;
