import React from 'react';
import BackupsListCreateDatastoreButton from './BackupsListCreateDatastoreButton';
import DeploymentsItem from '../../types/DeploymentsItem';
import { Menu } from 'antd';
import CcxIconPlusCircleOutlined from '../ccx/icons/CcxIconPlusCircleOutlined';
import styles from './IncrementalCreateDatastoreButton.module.less';

interface IncrementalCreateDatastoreButtonProps {
    deployment: DeploymentsItem;
    record: any;
}

const IncrementalCreateDatastoreButton = ({
    deployment,
    record,
}: IncrementalCreateDatastoreButtonProps) => {
    return (
        <Menu.Item key="createdatastore">
            <BackupsListCreateDatastoreButton
                deployment={deployment}
                record={record}
                actionIcon={
                    <div className={styles.BackupActionCreateDatastoreButton}>
                        <CcxIconPlusCircleOutlined /> Create Datastore
                    </div>
                }
            />
        </Menu.Item>
    );
};

export default IncrementalCreateDatastoreButton;
