import React, { ReactElement } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styles from './AppLogo.module.less';
import { AppLogoUrl } from '../../core/CcxEnv';
import User from '../../types/User';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setInitialState } from '../../slices/restore.slice';

export type AppLogoProps = {
    user?: User | undefined;
};

function AppLogo({ user }: AppLogoProps): ReactElement {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { isBackupRestore } = useAppSelector((state) => state.restoreBackup);

    const handleLogoClick = () => {
        if (isBackupRestore) {
            dispatch(setInitialState());
        }
        history.push('/projects/default/data-stores');
    };

    return (
        <div className={styles.AppLogo}>
            <NavLink
                to={'#'}
                data-testid="AppLogoLink"
                onClick={(e) => {
                    e.preventDefault();
                    handleLogoClick();
                }}
            >
                <img src={AppLogoUrl} alt="Logo" data-testid="AppLogoImage" />
            </NavLink>
        </div>
    );
}

export default AppLogo;
