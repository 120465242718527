import { Spin } from 'antd';
import AppConfirmDialog from '../AppConfirmDialog';
import { RedoOutlined } from '@ant-design/icons';
import styles from './IncrementalRestoreButton.module.less';

interface IncrementalRestoreButtonProps {
    readonly restoring?: boolean;
    readonly onConfirmRestore: Function;
}

const IncrementalRestoreButton = ({
    restoring,
    onConfirmRestore,
}: IncrementalRestoreButtonProps) => {
    return (
        <div>
            {restoring ? (
                <Spin />
            ) : (
                <AppConfirmDialog
                    onOk={onConfirmRestore}
                    actionIcon={
                        <a className={styles.RestoreButton}>
                            {' '}
                            <RedoOutlined /> Restore
                        </a>
                    }
                    title={<strong>Restore a backup</strong>}
                    content="You are going to restore your database using the selected backup."
                />
            )}
        </div>
    );
};

export default IncrementalRestoreButton;
